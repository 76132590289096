import type {LatLngLiteral} from "../interfaces/LatLngLitteral";
import {ConvertiblePosition} from "./ConvertiblePosition";

export class LatLng {

  lat:number;
  lng:number;

  constructor(lat :number, lng: number) {
    this.lat = lat;
    this.lng = lng;
  }

}
