import React, {Component} from 'react';
import {Tools} from "../Tools";

export default class MiniRouteColorBox extends Component {

    onRouteClicked(route){
        global.emitter.emit("onRouteSelected",route)
    }

    render(){

        if(!this.props.route){
            return null;
        }

        return(
            <div style={{height:18, display:"flex", justifyContent:"center", alignItems:'center', paddingLeft:4,
            paddingRight:4, backgroundColor:"#"+this.props.route.color, cursor:"pointer",
                border:this.props.adaptive === true ? "1px solid " + Tools.getContrastYIQ(this.props.route.color) : "1px solid #222222", width:38}}
                onClick={()=>{this.onRouteClicked(this.props.route)}}>
                <p style={{color:Tools.getContrastYIQ(this.props.route.color), fontSize:18, fontWeight:"bold", margin:0, cursor:"pointer"}}
                className={"noselect"}>{this.props.route.number}</p>
            </div>
        )
    }

}
