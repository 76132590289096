import React, {Component} from 'react';
import {Marker} from "react-google-maps";

export default class Stops extends Component {

    constructor(props){
        super(props);
    }

    onStopHovered(stop){
        global.emitter.emit("stopHovered",stop)
    }

    render() {

        if(!this.props.routeSelected){
            return null;
        }

        return (
            <template>
                {this.props.routeSelected.variants.map((variant)=> {
                    return(
                        <template key={"vr" + variant.id}>

                            {variant.stops.map((stop)=> {

                                return(
                                    <Marker key={"stop_" + stop.id + "_" + variant.id}
                                            position={{
                                                lat: stop.lat,
                                                lng: stop.lng,
                                            }}
                                            icon={this.getStopIcon(stop)}
                                            onMouseOver={()=>{this.onStopHovered(stop)}}
                                    >
                                    </Marker>

                                )
                            })}

                        </template>
                    )
                })}

            </template>
        )
    }

    getStopIcon(stop){
        if(stop.disabled){
            return({
                strokeColor: "#FF0000",
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: '#FF0000',
                fillOpacity:1,
                path: "M -1,-1 l 2,2 M -1,1 l 2,-2",
                scale: 4,
                anchor: new window.google.maps.Point(0, 0),
            })
        }
        else{
            return({
                    strokeColor: "#222222",
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    fillColor: '#FFFFFF',
                    fillOpacity:1,
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 5,
                    anchor: new window.google.maps.Point(0, 0),
            })
        }
    }
}
