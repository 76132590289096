import React, {Component} from 'react';
import {Polyline, InfoWindow} from "react-google-maps";
import RouteColorBox from "../shared/components/RouteColorBox";
import MiniRouteColorBox from "../shared/components/MiniRouteColorBox";

export default class Polylines extends Component {

    onPolylineClicked(route){
        this.props.onRouteSelected(route);
    }

    render() {

        if(this.props.routes.length === 0){return null}

        let routeBoundsPolyline = [];
        if(this.props.routeSelected){
            routeBoundsPolyline = [
                this.props.routeSelected.variants[0].decodedPolyline[0],
                this.props.routeSelected.variants[0].decodedPolyline[this.props.routeSelected.variants[0].decodedPolyline.length - 1],
            ]
        }

        return (
            <template>
                {this.props.routes.map((route)=>{

                    let tmpBoundsPolyline = [];
                    tmpBoundsPolyline = [
                        route.variants[0].decodedPolyline[0],
                        route.variants[0].decodedPolyline[route.variants[0].decodedPolyline.length - 1],
                    ];

                    return(
                        <template key={"route_" + route.id}>

                            {/*{!this.props.routeSelected && tmpBoundsPolyline.map((bound,index)=>{*/}
                            {/*    return(*/}
                            {/*        <InfoWindow key={"bound_" + index + route.id}*/}
                            {/*                    position={bound}>*/}
                            {/*            <MiniRouteColorBox route={route}/>*/}
                            {/*        </InfoWindow>*/}
                            {/*    )*/}
                            {/*})}*/}

                            {route.variants && route.variants.map((variant)=> {

                                let polylineZIndex = 20;
                                let polylineOutlineZIndex = 10;
                                let polylineColor = "#" + route.color;
                                let polylineOutlineColor = "#222222";
                                let polylineOpacity = 1;
                                let polylineOutlineOpacity = 1;

                                let icons = null;
                                let lineSymbol = {
                                    path: 'M -0.5,-1 -0.5,1 0.5,1, 0.5,-1 z',
                                    fillColor: "#F5F5F5",
                                    fillOpacity: 1,
                                    strokeOpacity:0,

                                };

                                if(route.traffic){
                                    if(route.traffic.serviceLevel > 0.75){
                                        polylineOpacity = 1;
                                        polylineOutlineOpacity = 1;
                                    }
                                    else if(route.traffic.serviceLevel <= 0.75 && route.traffic.serviceLevel > 0){
                                        // polylineOpacity = 0.6;
                                        // polylineOutlineOpacity = 0;

                                        icons= [{
                                            icon: lineSymbol,
                                            offset: '0',
                                            repeat:'25px'
                                        }];
                                    }
                                    else{
                                        polylineOpacity = 0.1;
                                        polylineOutlineOpacity = 0;
                                    }
                                }


                                if(this.props.routeSelected){

                                    if( this.props.routeSelected.id === route.id){
                                        polylineZIndex = 40;
                                        polylineOutlineZIndex = 30;
                                        polylineOpacity = 1;
                                        polylineOutlineOpacity = 1;
                                    }
                                    else{
                                        polylineColor = "#DDDDDD";
                                        polylineOutlineColor = "#797979";
                                        icons=null;
                                    }
                                }

                                return (
                                    <template key={"variant_" + variant.id + "_" + route.id}>


                                        <Polyline
                                            options={{
                                                strokeColor: polylineColor,
                                                strokeWeight: 5,
                                                zIndex:polylineZIndex,
                                                strokeOpacity:polylineOpacity,
                                                icons:icons
                                            }}
                                            path={variant.decodedPolyline}
                                            onClick={() => {this.onPolylineClicked(route)}}
                                        />

                                        <Polyline
                                            options={{
                                                strokeColor: polylineOutlineColor,
                                                strokeWeight: 7,
                                                zIndex: polylineOutlineZIndex,
                                                strokeOpacity:polylineOutlineOpacity,
                                            }}
                                            path={variant.decodedPolyline}
                                            onClick={() => {this.onPolylineClicked(route)}}
                                        />
                                    </template>
                                )
                            })}
                        </template>
                    )
                })}

                {routeBoundsPolyline.map((bound,index)=>{
                    return(
                        <InfoWindow key={"bound_" + index}
                        position={bound}>
                            <RouteColorBox route={this.props.routeSelected}/>
                        </InfoWindow>
                    )
                })}

            </template>
        )
    }
}
