export class Tools{

    static getAllNetworksInfo(){
        return [
            {
                dev:true,
                isTAD:false,
                internalUrl:"55697ratp",
                longName:"RATP - Réseau bus",
                shortName:"RATP ",
                serverUrl:"https://ratp.api.hubup.fr",
                defaultLatLng:{
                    lat:48.857602,
                    lng:2.345819
                }
            },
            {
                dev:true,
                isTAD:false,
                internalUrl:"00001demo",
                longName:"RATP - Demo trafic bus",
                shortName:"RATP ",
                serverUrl:"https://ratp.api.hubup.fr",
                defaultLatLng:{
                    lat:48.857602,
                    lng:2.345819
                }
            },
        ]

    }


    static getContrastYIQ(hexcolor){
        if(hexcolor.length === 7){
            hexcolor = hexcolor.substring(1);
        }

        let r = parseInt(hexcolor.substr(0,2),16);
        let g = parseInt(hexcolor.substr(2,2),16);
        let b = parseInt(hexcolor.substr(4,2),16);
        let yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 140) ? '#333333' : 'white';
    }

    static sortFlexnavRoutes(rawRoutes){

        let numberedRoutes = [];
        let textedRoutes = [];
        let allRoutes = [];

        for(let route of rawRoutes){
            if(isNaN(route.number)){
                textedRoutes.push(route)
            }
            else{
                numberedRoutes.push(route);
            }
        }

        numberedRoutes = numberedRoutes.sort((a,b) => {
            let numA = parseFloat(a.number);
            let numB = parseFloat(b.number);
            if (numA < numB)
                return -1;
            if (numA > numB)
                return 1;
            return 0;
        });

        textedRoutes = textedRoutes.sort((a,b)=>{
            if (a.number < b.number)
                return -1;
            if (a.number > b.number)
                return 1;
            return 0;
        });

        allRoutes = allRoutes.concat(numberedRoutes).concat(textedRoutes);

        return allRoutes;
    }

    static getPolylineBounds(track_points){
        return {
            minLat : Math.min.apply(null, track_points.map(function(d) { return d.latitude })),
            maxLat : Math.max.apply(null, track_points.map(function(d) { return d.latitude })),
            minLng : Math.min.apply(null, track_points.map(function(d) { return d.longitude })),
            maxLng : Math.max.apply(null, track_points.map(function(d) { return d.longitude }))
        }
    }

}
