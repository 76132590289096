import React, {Component} from 'react';
import './Home.css';
import GoogleMapsWrapper from "../shared/GoogleMapsWrapper";
import MapParameters from "../shared/MapParameters";


export default class Home extends Component {

    componentDidMount(){
        document.title ="404 | État du trafic";
    }

    render() {
        return (
            <div style={{height:"100%", width:"100%", position:"relative", display:"flex", flexDirection:"column",
            justifyContent:"center", alignItems:'center', backgroundColor:'#FFFFFF', overflow:"hidden"}}>

                <GoogleMapsWrapper
                    // googleMapURL={"https://maps.googleapis.com/maps/api/js?v=weekly&key=AIzaSyA3L1L3cqBzEmPCtFhx73Yqp5czbn9q_dU"}
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?v=weekly&key=AIzaSyDS2rmKq2vRbYSbzYa11tqdsw2GdP6Ka2E"}
                    loadingElement={<div style={{height: `100%`, width:'100%'}}/>}
                    containerElement={<div style={{height: `100%`, width:'100%'}}/>}
                    mapElement={<div style={{height: `100%`, width:'100%'}}/>}
                    defaultZoom={13}
                    defaultCenter={{lat: 48.864716, lng: 2.349014}}
                    defaultOptions={{mapTypeControl: false, streetViewControl: false, fullscreenControl: false, zoomControl:false, clickableIcons: false,
                        styles:MapParameters.ratp_ultra_light}}
                >
                </GoogleMapsWrapper>

                <div style={{height:"140%", width:"140%", position:"absolute", left:"-20%", top:"-20%", display:"flex",
                    justifyContent:"center", alignItems:'center', backgroundColor:"rgba(0,0,0,0.5)", flexDirection:"column"}}>

                    <span style={{color:'#FFFFFF', fontSize:200, fontWeight:"bold"}}>404</span>
                    <p style={{width:340, textAlign:"center", margin:0, marginTop:-20, color:'#FFFFFF', fontSize:22}}>
                        {"Désolé, nous n'avons pas pu trouver la page que vous recherchez 😞"}
                    </p>

                </div>

            </div>
        )
    }
}
