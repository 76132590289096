
import {Tools} from "../../shared/Tools";
import {Encoder} from "../../shared/geo-utilities/others/Encoder";
import moment from "moment";

export default class NetworkMapApi{

    static getRATPMontrougeData(){
        return new Promise((resolve)=>{

           let url = "https://flexnav.disruption.api.hubup.fr/exposed/disruptions/RATP/update/by/company/tag";
           // let url = "https://flexnav.disruption.api.hubup.fr/exposed/disruptions/PREX2/update/by/company/tag";
           fetch(url)
               .then((response)=>{
                   return response.json()
               })
               .then((lastDisruptionUpdate)=>{
                   let dateTime = moment(lastDisruptionUpdate.dateTime).format("dddd DD MMMM");
                   let umd = NetworkMapApi.unminifyData(JSON.parse(lastDisruptionUpdate.data));

                   for(let route of umd){
                       route.lastUpdate = dateTime;
                   }

                   // console.log("UMD",umd);
                   let result = umd;
                   for(let route of result){
                       route.textColor = Tools.getContrastYIQ(route.color);
                       route.color = route.color.substring(1);

                       if(route.traffic.status === "DISRUPTED"){
                           route.traffic.serviceLevel = 0.74
                       }
                       else if(route.traffic.status === "INTERRUPTED"){
                           route.traffic.serviceLevel = 0
                       }
                       else{
                           route.traffic.serviceLevel = 1
                       }

                       if(route.stopsDisrupted && route.stopsDisrupted.length > 0){

                           let variantsDisrupted = [];

                           for(let variant of route.variants){

                               let variantStopsDisrupted = [];

                               for(let stop of variant.stops){
                                   if(route.stopsDisrupted.indexOf(stop.id) !== -1){
                                       stop.disabled = true;
                                       variantStopsDisrupted.push(stop);
                                   }
                               }

                               if(variantStopsDisrupted.length > 0){
                                   variantsDisrupted.push({
                                       variant:variant,
                                       stopsDisrupted:variantStopsDisrupted
                                   })
                               }
                           }

                           route.variantsDisrupted = variantsDisrupted;
                       }
                   }

                   result = Tools.sortFlexnavRoutes(result);

                   resolve(result);
               })
               .catch((err)=>{
                   console.log(err)
               });


           // setTimeout(()=>{
           //
           //     //DEV ONLY
           //     // console.log(result);
           //     // NetworkMapApi.generateMinifiedData(result);
           //
           //     let minifiedData = MontrougeData.getData();
           //     // console.log(JSON.stringify(minifiedData));
           //     // let minifiedData = AllData.getData();
           //     let unminifiedData = NetworkMapApi.unminifyData(minifiedData);
           //
           //     let result = unminifiedData;
           //     for(let route of result){
           //
           //         route.textColor = Tools.getContrastYIQ(route.color);
           //
           //         if(route.number + "" === "21"){
           //
           //         }
           //         if(route.number + "" === "28"){
           //             route.traffic = {
           //                 status:"DISRUPTED",
           //                 detail:"Service réduit avec 3 bus sur 4 en circulation",
           //                 serviceLevel:0.74,
           //             };
           //         }
           //         if(route.number + "" === "38"){
           //             route.traffic = {
           //                 status:"DISRUPTED",
           //                 detail:"Jusqu'à 10h00 Reprise de 16h30 à 20h30",
           //                 serviceLevel:0.74,
           //             };
           //         }
           //         if(route.number + "" === "59"){
           //
           //         }
           //         if(route.number + "" === "67"){
           //             route.traffic = {
           //                 status:"DISRUPTED",
           //                 detail:"Service réduit avec 3 bus sur 4 en circulation",
           //                 serviceLevel:0.74,
           //             };
           //         }
           //         if(route.number + "" === "88"){
           //             // route.traffic = {
           //             //     status:"DISRUPTED",
           //             //     detail:"Service réduit avec 3 bus sur 4 en circulation",
           //             //     serviceLevel:0.74,
           //             // };
           //         }
           //         if(route.number + "" === "92"){
           //             route.traffic = {
           //                 status:"DISRUPTED",
           //                 detail:"Service réduit avec 2 bus sur 3 en circulation",
           //                 serviceLevel:0.74,
           //             };
           //         }
           //         if(route.number + "" === "94"){
           //             route.traffic = {
           //                 status:"DISRUPTED",
           //                 detail:"Service réduit avec 1 bus sur 4 en circulation",
           //                 serviceLevel:0.74,
           //             };
           //         }
           //         if(route.number + "" === "109"){
           //             route.traffic = {
           //                 status:"INTERRUPTED",
           //                 detail:"Service non-assuré",
           //                 serviceLevel:0,
           //             };
           //         }
           //         if(route.number + "" === "119"){
           //
           //         }
           //         if(route.number + "" === "125"){
           //             route.traffic = {
           //                 status:"DISRUPTED",
           //                 detail:"Service réduit avec 2 bus sur 3 en circulation",
           //                 serviceLevel:0.74,
           //             };
           //         }
           //         if(route.number + "" === "126"){
           //             route.traffic = {
           //                 status:"DISRUPTED",
           //                 detail:"Service réduit avec 2 bus sur 3 en circulation",
           //                 serviceLevel:0.74,
           //             };
           //         }
           //         if(route.number + "" === "128"){
           //
           //             // route.traffic = {
           //             //     status:"DISRUPTED",
           //             //     detail:"Service réduit avec 2 bus sur 3 en circulation",
           //             //     serviceLevel:0.74,
           //             // };
           //
           //
           //             if(global.networkId &&  global.networkId === "00001demo"){
           //              console.warn("DEMO DETECTED")
           //                 for(let variant of route.variants){
           //                     for(let stop of variant.stops){
           //                         if(stop.name === "Rue de l'égalité" || stop.name === "Pasteur"){
           //                             stop.disabled = true;
           //                         }
           //                     }
           //                 }
           //                 route.disruptions = [
           //                     {
           //                         type:"CONSTRUCTION",
           //                         from:"01122019",
           //                         to:"09012020",
           //                         title:"Travaux avenue Salvador Allende",
           //                         body:"En raison d'importants travaux de voirie sur l'avenue Salvador Allende à Bagneux, les arrêts Rue de l'égalité et " +
           //                             "Pasteur ne sont pas desservis dans les deux sens de circulation à compter du 01/12/2019 et ce jusqu'au 09/01/2020. " +
           //                             "Merci de votre compréhension."
           //                     }
           //                 ];
           //             }
           //         }
           //         if(route.number + "" === "187"){
           //             route.traffic = {
           //                 status:"DISRUPTED",
           //                 detail:"Service réduit avec 1 bus sur 2 en circulation",
           //                 serviceLevel:0.74,
           //             };
           //         }
           //         if(route.number + "" === "188"){
           //
           //         }
           //         if(route.number + "" === "196"){
           //
           //         }
           //         if(route.number + "" === "197"){
           //             route.traffic = {
           //                 status:"DISRUPTED",
           //                 detail:"Service réduit avec 3 bus sur 4 en circulation",
           //                 serviceLevel:0.74,
           //             };
           //         }
           //         if(route.number + "" === "199"){
           //
           //         }
           //         if(route.number + "" === "216"){
           //             route.traffic = {
           //                 status:"DISRUPTED",
           //                 detail:"Service réduit avec 2 bus sur 3 en circulation",
           //                 serviceLevel:0.74,
           //             };
           //         }
           //         if(route.number + "" === "291"){
           //
           //         }
           //         if(route.number + "" === "297"){
           //             // route.traffic = {
           //             //     status:"DISRUPTED",
           //             //     detail:"Service réduit avec 3 bus sur 4 en circulation",
           //             //     serviceLevel:0.74,
           //             // };
           //         }
           //         if(route.number + "" === "299"){
           //
           //         }
           //         if(route.number + "" === "319"){
           //
           //         }
           //         if(route.number + "" === "379"){
           //
           //         }
           //         if(route.number + "" === "395"){
           //             // route.traffic = {
           //             //     status:"DISRUPTED",
           //             //     detail:"Service réduit avec 2 bus sur 3 en circulation",
           //             //     serviceLevel:0.74,
           //             // };
           //         }
           //         if(route.number + "" === "399"){
           //             // route.traffic = {
           //             //     status:"DISRUPTED",
           //             //     detail:"Service réduit avec 3 bus sur 4 en circulation",
           //             //     serviceLevel:0.74,
           //             // };
           //         }
           //     }
           //     result = Tools.sortFlexnavRoutes(result);
           //
           //    resolve(result);
           // },1000)
        })
    }

    static generateMinifiedData(rawData){
        let data = [];
        for(let rawRoute of rawData){
            let route = {
                i:rawRoute.id,
                c:rawRoute.color,
                d:rawRoute.description,
                n:rawRoute.number,
                v:[],
            };

            for(let rawVariant of rawRoute.variants){
                let variant = {
                    i:rawVariant.id,
                    a:rawVariant.Arrival,
                    d:rawVariant.Departure,
                    t:rawVariant.variantType,
                    p:rawVariant.encodedPolyLine[0].encodedPolyLine,
                    s:[],
                };
                for(let rawStop of rawVariant.stops){
                    let stop = {
                        i:rawStop.id,
                        n:rawStop.name,
                        la:Math.floor(rawStop.lat*1E6)/1E6,
                        ln:Math.floor(rawStop.lng*1E6)/1E6,
                    };
                    variant.s.push(stop);
                }
                route.v.push(variant);
            }

            data.push(route);
        }

        console.log(JSON.stringify(data));
    }


    static unminifyData(minData){
        let result = [];
        for(let minRoute of minData){
            let route = {
                id:minRoute.i,
                color:minRoute.c,
                description:minRoute.d,
                number:minRoute.n,
                traffic:{
                    status:minRoute.ts,
                    detail:minRoute.td,
                },
                stopsDisrupted:minRoute.ds && minRoute.ds.length > 0 ? minRoute.ds : [],
                variants:[],
            };
            for(let minVariant of minRoute.v){
                let variant = {
                    id:minVariant.i,
                    arrival:minVariant.a,
                    departure:minVariant.d,
                    variantType:minVariant.t,
                    decodedPolyline:Encoder.decodePolyline(minVariant.p,6),
                    stops:[],
                };
                for(let minStop of minVariant.s){
                    let stop = {
                        id:minStop.i,
                        name:minStop.n,
                        lat:minStop.la,
                        lng:minStop.ln,
                    };
                    variant.stops.push(stop);
                }
                route.variants.push(variant);
            }
            result.push(route);
        }
        return result;
    }

}
