import React, {Component} from 'react';
import './NetworkMap.css';
import {Tools} from "../shared/Tools";
import GoogleMapsWrapper from "../shared/GoogleMapsWrapper";
import MapParameters from"../shared/MapParameters"
import NetworkMapApi from "./api/NetworkMapApi";
import Polylines from "./Polylines";
import Stops from "./Stops";
import HoveredStop from "./HoveredStop";
import RouteColorBox from "../shared/components/RouteColorBox";

//Material modules
import {AppBar, Snackbar, SwipeableDrawer, Drawer, IconButton, Button, Dialog, DialogTitle, Avatar, List, ListItemText, DialogActions,
    ListItem,ListItemIcon, MuiThemeProvider, Icon, Tooltip, Menu, MenuItem, Paper, LinearProgress, CircularProgress, DialogContent,
    DialogContentText, FormControl, InputLabel, Select, OutlinedInput, TextField, FormHelperText, InputAdornment} from "@material-ui/core";

import moment from "moment";
import frLocale from 'moment/locale/fr';
import RouteList from "./routeList/RouteList";
import {LatLngBounds} from "../shared/geo-utilities/entities/LatLngBounds";
import RoutesIndices from "./RoutesIndices";
import {Marker} from "react-google-maps";

export default class NetworkMap extends Component {

    constructor(props){
        super(props);

        this.state={
            networkInfo:this.getNetworkDataFromNetworkId(),
            routes:[],
            routeSelected:null,
            showDisruptions:false,
            routeSelectedOnce:false,
            userGeolocation:null,
        }
    }

    _onRouteSelectedSubscription:any;

    componentDidMount(){

        moment.locale('fr',frLocale);

        if(this.state.networkInfo === null){
            this.props.history.replace('/notfound');
        }
        else{
            this.loadData();
        }

        this._onRouteSelectedSubscription = global.emitter.addListener("onRouteSelected",(route)=>{
            this.onRouteSelected(route);
        });

        setTimeout(()=>{
            this.tryEnableGeolocation()
        },1500)
    }

    _geolocationWatcher:any;
    _firstPositionReceived:boolean = false;

    tryEnableGeolocation(){
        if ("geolocation" in navigator) {
            this._geolocationWatcher = navigator.geolocation.watchPosition( (position)=>{
                this.setState({
                    userGeolocation:position.coords
                },()=>{
                    if(this._firstPositionReceived === false){
                        this._firstPositionReceived = true;
                        this.fitMapToPosition(position.coords);
                    }
                })
            });
        }
    }

    loadData(){
        NetworkMapApi.getRATPMontrougeData()
            .then((data)=>{
                this.setState({
                    routes:data
                },()=>{
                    global.emitter.emit("routesLoaded");
                })
            })
    }

    componentWillUnmount(){
        if(this._onRouteSelectedSubscription){this._onRouteSelectedSubscription.remove()}
        if(this._geolocationWatcher){
            navigator.geolocation.clearWatch(this._geolocationWatcher);
        }
    }

    _mapRef;

    onMapMounted(map){
        this._mapRef = map;
        global.emitter.emit("mapZoomChanged",13);
    }

    onMapClicked(){

    }

    onMapDragged(){

    }

    onZoomChanged(){
        try{
            let zoom = this._mapRef.getZoom();
            global.emitter.emit("mapZoomChanged",zoom);
        }
        catch(err){
            console.log(err);
        }
    }

    onRouteSelected(route){
        global.emitter.emit("stopHovered",null);
        this.setState({
            routeSelected:route,
            showDisruptions:false,
            routeSelectedOnce:true,
        },()=>{
            if(route !== null){
                let allPoints = [];
                for(let variant of route.variants){
                    allPoints.push(variant.decodedPolyline[0]);
                    allPoints.push(variant.decodedPolyline[variant.decodedPolyline.length - 1]);
                }

                let bounds = Tools.getPolylineBounds(allPoints);
                this.fitMapToBounds(bounds);
            }
        })
    }

    toggleShowDistuptions(){
        this.setState({
            showDisruptions:!this.state.showDisruptions
        })
    }

    render() {
        return (
            <div style={{height:"100%", width:"100%", position:"relative", display:"flex", flexDirection:"column",
            justifyContent:"center", alignItems:'center'}}>


                <GoogleMapsWrapper
                    // googleMapURL={"https://maps.googleapis.com/maps/api/js?v=weekly&key=AIzaSyA3L1L3cqBzEmPCtFhx73Yqp5czbn9q_dU"}
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?v=weekly&key=AIzaSyDS2rmKq2vRbYSbzYa11tqdsw2GdP6Ka2E"}
                    loadingElement={<div style={{height: `100%`, width:'100%'}}/>}
                    containerElement={<div style={{height: `100%`, width:'100%'}}/>}
                    mapElement={<div style={{height: `100%`, width:'100%'}}/>}
                    onMapMounted={(map) => {this.onMapMounted(map)}}
                    defaultZoom={13}
                    defaultCenter={{lat: this.state.networkInfo ? this.state.networkInfo.defaultLatLng.lat : 48.864716, lng: this.state.networkInfo ? this.state.networkInfo.defaultLatLng.lng : 2.349014}}
                    onClick={()=>{this.onMapClicked()}}
                    onDrag={()=>{this.onMapDragged()}}
                    onZoomChanged={()=>{this.onZoomChanged()}}
                    defaultOptions={{mapTypeControl: false, streetViewControl: false, fullscreenControl: false, zoomControl:false, clickableIcons: false,
                        styles:MapParameters.ratp_light, gestureHandling: "greedy"}}
                >

                    <Polylines
                        routes={this.state.routes}
                        routeSelected={this.state.routeSelected}
                        onRouteSelected={(route)=>{this.onRouteSelected(route)}}
                    />

                    <Stops
                        routeSelected={this.state.routeSelected}
                    />

                    <HoveredStop/>

                    <RoutesIndices
                        routeSelected={this.state.routeSelected}
                        routes={this.state.routes}
                    />

                    {this.state.userGeolocation &&
                        <template>
                            <Marker position={{
                                        lat: this.state.userGeolocation.latitude,
                                        lng: this.state.userGeolocation.longitude,
                                    }}
                                    zIndex={2}
                                    icon={{
                                        strokeColor: "#FFFFFF",
                                        strokeOpacity: 1,
                                        strokeWeight: 1,
                                        fillColor: '#3F98F4',
                                        fillOpacity:1,
                                        path: window.google.maps.SymbolPath.CIRCLE,
                                        scale: 6,
                                        anchor: new window.google.maps.Point(0, 0),
                                    }}
                            >
                            </Marker>
                            <Marker position={{
                                        lat: this.state.userGeolocation.latitude,
                                        lng: this.state.userGeolocation.longitude,
                                    }}
                                    zIndex={1}
                                    icon={{
                                        strokeColor: "#3f98f4",
                                        strokeOpacity: 0.5,
                                        strokeWeight: 1,
                                        fillColor: '#3F98F4',
                                        fillOpacity:0.3,
                                        path: window.google.maps.SymbolPath.CIRCLE,
                                        scale: 30,
                                        anchor: new window.google.maps.Point(0, 0),
                                    }}
                            >
                            </Marker>
                        </template>
                    }

                </GoogleMapsWrapper>

                <div style={{height:40, width:"100%", position:"absolute", top:0, left:0, backgroundColor:"#973636", paddingLeft:12,
                paddingRight:12, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center'}} className={"shadowed-menu"}>
                    <Icon style={{color:"#FFFFFF", fontSize:18, marginRight:10}}>info</Icon>
                    <p style={{width:"100%", textAlign:"left", color:"#FFFFFF", margin:0, fontWeight:"bold"}} className={"band-text"}>
                        {"Pour la santé de tous, merci de respecter la distanciation physique. Le port du masque est OBLIGATOIRE dans les transports en communs."}
                    </p>
                </div>

                {!this.state.routeSelected &&
                <div style={{width:440, position:"absolute", left:10, top:50, maxWidth:"calc(100vw - 20px)"}}>
                    <div style={{borderRadius:4, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'flex-end', height:46, width:"100%"}}>

                        <img alt={"ratp_infotrafic"} src={require("./../../assets/ratp_infotrafic.png")} style={{width:44}}/>

                        <div style={{flex:1, height:"100%", borderRadius:4, marginLeft:10, backgroundColor:"#2F549C", display:"flex",
                        flexDirection:"row"}} className={"shadowed-menu"}>

                            <div style={{flex:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:'flex-start',
                                paddingLeft:8}}>
                                <span style={{color:"#FFFFFF", fontSize:16, fontWeight:"bold"}}>Information trafic BUS</span>
                                <span style={{color:"#FFFFFF", fontSize:14, fontWeight:"normal"}}>{this.state.routes.length > 0 ? "Dernière mise à jour le " + this.state.routes[0].lastUpdate : "---"}</span>
                            </div>

                            <div style={{width:44, height:"100%", padding:4, marginRight:3, paddingTop:6}}>
                                <img alt={"ratp_infotrafic"} src={require("./../../assets/RATP.svg")} style={{width:"100%"}} />
                            </div>


                        </div>
                    </div>

                    <div style={{borderRadius:4, width:"100%", backgroundColor:'#FFFFFF', minHeight:44, marginTop:10}} className={"shadowed-menu"}>

                        {this.state.routes.length === 0 &&
                        <div style={{borderRadius:4, height:46, width:"100%", overflow:"hidden"}}>
                            <div style={{height:"100%", width:"100%", display:"flex", justifyContent:"center", alignItems:'center'}} className={"striped-loader"}>
                                <p style={{width:"100%", display:"flex", justifyContent:"center", alignItems:'center', margin:0, color:'#797979', fontSize:14}}>
                                    CHARGEMENT DES LIGNES EN COURS
                                </p>
                            </div>
                        </div>
                        }

                        {this.state.routes.length > 0 &&
                            <RouteList
                                routes={this.state.routes}
                                routeSelectedOnce={this.state.routeSelectedOnce}
                                onRouteSelected={(route)=>{this.onRouteSelected(route)}}
                            />
                        }

                    </div>
                </div>
                }

                {this.state.routeSelected &&
                <div style={{width:440, position:"absolute", left:10, top:50, borderRadius:4, display:"flex",
                    flexDirection:"column", overflow:"hidden",  maxWidth:"calc(100vw - 20px)"}}  className={"shadowed-menu"}>

                    <div style={{width:"100%", height:46, backgroundColor:"#FFFFFF",
                        borderBottom:"1px solid #CCCCCC" , display:"flex", flexDirection:"row", justifyContent:"flex-start",
                    alignItems:'center', paddingLeft:8, paddingRight:2}}>
                        <RouteColorBox route={this.state.routeSelected}/>
                        <div style={{flex:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:'flex-start',
                        paddingLeft:8}}>
                             <span style={{color:"#222222", fontSize:16, fontWeight:"bold"}}>Information trafic BUS</span>
                             <span style={{color:"#222222", fontSize:14, fontWeight:"normal"}}>{"Dernière mise à jour le " +  this.state.routeSelected.lastUpdate}</span>
                        </div>

                        <IconButton
                            style={{height:34, width:34, marginRight:6}}
                            onClick={()=>{this.onRouteSelected(null)}}
                        >
                            <Icon style={{fontSize:22, color:"#222222", marginTop:-6}}>clear</Icon>
                        </IconButton>


                    </div>
                    <div style={{minHeight:50, width:"100%", backgroundColor:'#FAFAFA', padding:8, maxHeight:440, overflowY:"auto"}}
                         className={"styled-scroller"}>
                        {this.getRouteTrafficBox(this.state.routeSelected)}

                        {this.state.routeSelected.disruptions &&
                            <div style={{width:"100%", justifyContent:"center", alignItems:'center', height:20, marginTop:10, flexDirection:"row"}} className={"disruption-toggle"}
                            onClick={()=>{this.toggleShowDistuptions()}}>
                                <span style={{color:'#2F549C', fontSize:14}}>{this.state.showDisruptions ? "Masquer " : "Afficher "} les perturbations</span>
                                <Icon style={{fontSize:18, color:"#2F549C", marginLeft:3}}>keyboard_arrow_down</Icon>
                            </div>
                        }

                        {this.state.routeSelected.disruptions && this.state.routeSelected.disruptions.length > 0 &&
                            <div style={{width:"100%"}} className={this.state.showDisruptions === false ? "hidden-disruptions" : ""}>
                                {this.state.routeSelected.disruptions.map((disruption,index)=>{
                                    return(
                                        <div style={{width:"100%", border:"1px solid #E4842B", overflow:"hidden", borderRadius:4,
                                        marginTop:10, position:"relative"}} key={"disruption" + index}>

                                            <div style={{height:"100%", width:4, backgroundColor:"#E4842B", position:"absolute",
                                            left:0, top:0}}/>

                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center', backgroundColor:"#FFFFFF",
                                                padding:2, borderBottom:"1px solid #E4842B", paddingLeft:6}}>
                                                <Icon style={{fontSize:18, color:"#555555", marginRight:3}}>event</Icon>

                                                <span style={{fontSize:14, fontWeight:"bold", color:'#222222', margin:0}}>
                                                    {moment(disruption.from,"DDMMYYYY").format("DD/MM/YY")}
                                                </span>
                                                <Icon style={{fontSize:14, color:"#555555"}}>arrow_forward</Icon>
                                                <span style={{fontSize:14, fontWeight:"bold", color:'#222222', margin:0}}>
                                                    {moment(disruption.to,"DDMMYYYY").format("DD/MM/YY")}
                                                </span>

                                            </div>
                                            <div style={{display:"flex", justifyContent:"flex-start", alignItems:'center', backgroundColor:"#FFFFFF",
                                                padding:4, flexDirection:"column", paddingLeft:8}}>

                                                <p style={{fontWeight:"bold", width:"100%", textAlign:"left", color:'#222222', fontSize:14, margin:0, marginBottom:4}}>
                                                    {disruption.title}
                                                </p>

                                                <p style={{fontWeight:"normal", width:"100%", textAlign:"left", color:'#555555', fontSize:14, margin:0}}>
                                                    {disruption.body}
                                                </p>

                                            </div>


                                        </div>
                                    )
                                })}
                            </div>
                        }

                        {this.state.routeSelected.variantsDisrupted && this.state.routeSelected.variantsDisrupted.length > 0 &&
                            <div style={{width:"100%"}}>
                                {this.state.routeSelected.variantsDisrupted.map((variant)=>{
                                    return(
                                        <div style={{width:"100%", borderRadius:4, border:"1px solid #CCCCCC", backgroundColor:'#FFFFFF',
                                        marginTop:10, padding:10}} key={"variant" + variant.id} onClick={()=>{console.log(variant)}}>
                                            <p style={{width:"100%", margin:0, textAlign:"left", fontSize:14, color:"#555555"}}>
                                                {variant.variant.departure}
                                                <span style={{fontWeight:"bold",color:"#222222"}}>{" "} {"⟶"}{" "}</span>
                                                {variant.variant.arrival}
                                                <strong style={{color:"#222222"}}>{" " + variant.stopsDisrupted.length + " arrêt(s) non-desservi(s)"}</strong>
                                            </p>
                                            <div style={{height:1, width:"40%", backgroundColor:'#CCCCCC', marginTop:10, marginBottom:10}}/>
                                            {variant.stopsDisrupted.map((stop)=>{
                                                return(
                                                    <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center'}}
                                                    key={"stop" + stop.id}>
                                                        <Icon style={{fontSize:18, color:"#EE3E3B", marginRight:4}}>clear</Icon>
                                                        <p style={{margin:0, fontSize:14, color:'#222222', flex:1,textAlign:"left", fontWeight:"bold"}}>{stop.name}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>

                </div>
                }

                {this.state.userGeolocation &&
                    <Tooltip title={"Centrer sur ma position"} placement={"left"}>
                        <div style={{height:45, width:45, backgroundColor:'#FFFFFF', borderRadius:"50%", position:"absolute",
                            right:20, bottom:20, display:"flex", justifyContent:"center", alignItems:'center', cursor:"pointer"}} className={"shadowed-fab"}
                             onClick={()=>{this.fitMapToPosition(this.state.userGeolocation)}}>

                            <Icon style={{fontSize:20, color:"#2F549C"}}>my_location</Icon>
                        </div>
                    </Tooltip>
                }

            </div>
        )
    }


    getNetworkDataFromNetworkId(){
        let networkId:string = this.props.match.params.networkId;
        global.networkId = this.props.match.params.networkId;

        let networkInfo = null;

        let allNetworks = Tools.getAllNetworksInfo();
        for(let network of allNetworks){
            if(network.internalUrl === networkId){
                networkInfo = network;
                document.title = networkInfo.longName;
                global.serverUrl = networkInfo.serverUrl;
                break;
            }
        }

        return networkInfo
    }

    getRouteTrafficBox(route){

        let status = null;
        if(route.traffic){
            status = route.traffic.status;
        }

        switch(status){

            case "DISRUPTED":
                return(
                    <div style={{width:"100%", border:"1px solid #E58527", overflow:"hidden", borderRadius:4}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',
                            backgroundColor:"#E58527", padding:2}}>
                            <p style={{textAlign:"right", fontSize:14, fontWeight:"bold", color:'#FFFFFF', margin:0, marginRight:4, marginLeft:4}}>
                                {"TRAFIC PERTURBÉ"}
                            </p>
                            <Icon style={{fontSize:18, color:"#FFFFFF"}} className={"inverted"}>info</Icon>
                        </div>
                        <div style={{padding:6, width:"100%", backgroundColor:'#FFFFFF'}}>
                            <p style={{color:'#555555', textAlign:"left", fontSize:14, width:"100%", margin:0}}>
                                {route.traffic.detail}
                            </p>
                        </div>
                    </div>
                );
            case "VERY_DISRUPTED":
                return(
                    <div style={{width:"100%", border:"1px solid #E58527", overflow:"hidden", borderRadius:4}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',
                            backgroundColor:"#E58527", padding:2}}>
                            <p style={{textAlign:"right", fontSize:14, fontWeight:"bold", color:'#FFFFFF', margin:0, marginRight:4, marginLeft:4}}>
                                {"TRAFIC TRÈS PERTURBÉ"}
                            </p>
                            <Icon style={{fontSize:18, color:"#FFFFFF"}} className={"inverted"}>info</Icon>
                        </div>
                        <div style={{padding:6, width:"100%", backgroundColor:'#FFFFFF'}}>
                            <p style={{color:'#555555', textAlign:"left", fontSize:14, width:"100%", margin:0}}>
                                {route.traffic.detail}
                            </p>
                        </div>
                    </div>
                );
            case "INTERRUPTED":
                return(
                    <div style={{width:"100%", border:"1px solid #E43525", overflow:"hidden", borderRadius:4}}>

                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',
                            backgroundColor:"#E43525", padding:2,}}>
                            <p style={{textAlign:"right", fontSize:14, fontWeight:"bold", color:'#FFFFFF', margin:0, marginRight:4, marginLeft:4}}>
                                {"TRAFIC INTERROMPU"}
                            </p>
                            <Icon style={{fontSize:18, color:"#FFFFFF"}}>cancel</Icon>
                        </div>
                        <div style={{padding:6, width:"100%", backgroundColor:'#FFFFFF'}}>
                            <p style={{color:'#555555', textAlign:"left", fontSize:14, width:"100%", margin:0}}>
                                {route.traffic.detail}
                            </p>
                        </div>
                    </div>
                );
            default:
                return(
                    <div style={{width:"100%", border:"1px solid #569F43", overflow:"hidden", borderRadius:4}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',
                        backgroundColor:"#569F43", padding:2}}>
                            <p style={{textAlign:"right", fontSize:14, fontWeight:"bold", color:'#FFFFFF', margin:0, marginRight:4, marginLeft:4}}>
                                {"Trafic normal"}
                            </p>
                            <Icon style={{fontSize:18, color:"#FFFFFF"}}>check_circle</Icon>
                        </div>
                        <div style={{padding:6, width:"100%", backgroundColor:'#FFFFFF'}}>
                            <p style={{color:'#555555', textAlign:"left", fontSize:14, width:"100%", margin:0}}>
                                {"Circulation normale des bus sur l'ensemble de la ligne"}
                            </p>
                        </div>
                    </div>

                )

        }

    }

    fitMapToBounds(bounds){
        if(bounds && bounds.minLat && bounds.minLng && bounds.maxLat && bounds.maxLng){
            try{
                this._mapRef.fitBounds(new LatLngBounds({lat:bounds.minLat, lng:bounds.minLng}, {lat:bounds.maxLat, lng:bounds.maxLng}).toGoogleLatLngBouldsLiteral());
            }
            catch(error){
                console.log("Access to map reference denied: Google map is not mounted yet");
                setTimeout(()=>{
                    this.fitMapToBounds(bounds);
                },300)
            }
        }
    }

    fitMapToPosition(position){
        let bounds = {
            minLat:position.latitude - 0.002,
            minLng:position.longitude - 0.002,
            maxLat:position.latitude + 0.002,
            maxLng:position.longitude + 0.002,
        };
        this.fitMapToBounds(bounds);
    }

}
