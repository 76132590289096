import React, {Component} from 'react';
import './App.css';
import {EventEmitter} from "fbemitter";
import { BrowserRouter as Router, Route,  Switch, Redirect} from 'react-router-dom';
import Home from "./modules/home/Home";
import NetworkMap from "./modules/NetworkMap/NetworkMap";

class App extends Component {

  constructor(props){
    super(props);
    global.emitter = new EventEmitter();
  }

  render() {
    return (
        <div className={"app"}>
            <Router>
              <Switch>
                <Route path="/notfound" exact={true} history={this.props.history} match={this.props.match}  render={(props) => (
                    <Home {...props}/>
                )}/>
                <Route path="/n/:networkId" exact={true} history={this.props.history} match={this.props.match}  render={(props) => (
                    <NetworkMap {...props}/>
                )}/>
                <Redirect from="/" to="/notfound" />
              </Switch>
            </Router>
        </div>
    )
  }
}

export default App;
