import React, {Component} from 'react';
import {Marker} from "react-google-maps";

export default class HoveredStop extends Component {

    constructor(props){
        super(props);

        this.state = {
            stop:null
        }
    }

    _stopHoveredSubscription:any;

    onStopHovered(stop){
        this.setState({
            stop:stop
        })
    }

    componentDidMount(): void {
        this._stopHoveredSubscription = global.emitter.addListener("stopHovered",(stop)=>{
            this.onStopHovered(stop);
        })
    }

    componentWillUnmount(): void {
        if(this._stopHoveredSubscription){this._stopHoveredSubscription.remove()}
    }

    render() {

        if(!this.state.stop){
            return null;
        }

        return (

            <template>
                <Marker
                    position={{
                        lat: this.state.stop.lat,
                        lng: this.state.stop.lng,
                    }}
                    icon={this.getStopIcon(this.state.stop)}
                    zIndex={100}
                    label={{
                        text: this.state.stop.name,
                        color: '#222222',
                        fontSize:"12px"
                    }}
                />
                <Marker icon={{
                    strokeColor: '#222222',
                    strokeOpacity: 1.0,
                    strokeWeight: 1,
                    fillColor: '#222222',
                    fillOpacity: 0.2,
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 20,
                    anchor: new window.google.maps.Point(0, 0)
                }}
                        position={{lat: this.state.stop.lat, lng: this.state.stop.lng}}
                        zIndex={30}
                />

            </template>

        )
    }

    getStopIcon(stop){
        if(stop.disabled){
            return({
                strokeColor: "#FF0000",
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: '#FF0000',
                fillOpacity:1,
                path: "M -1,-1 l 2,2 M -1,1 l 2,-2",
                scale: 4,
                anchor: new window.google.maps.Point(0, 0),
                labelOrigin: { x: 7 + stop.name.length/1.9, y:0}
            })
        }
        else{
            return({
                strokeColor: "#222222",
                strokeOpacity: 1,
                strokeWeight: 1,
                fillColor: '#FFFFFF',
                fillOpacity:1,
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 5,
                anchor: new window.google.maps.Point(0, 0),
                labelOrigin: { x: 6 + stop.name.length/1.9, y:0}
            })
        }
    }
}
