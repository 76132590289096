import React, {Component} from 'react';
import "./RouteList.css";
import RouteColorBox from "../../shared/components/RouteColorBox";

//Material modules
import {AppBar, Snackbar, SwipeableDrawer, Drawer, IconButton, Button, Dialog, DialogTitle, Avatar, List, ListItemText, DialogActions,
    ListItem,ListItemIcon, MuiThemeProvider, Icon, Tooltip, Menu, MenuItem, Paper, LinearProgress, CircularProgress, DialogContent,
    DialogContentText, FormControl, InputLabel, Select, OutlinedInput, TextField, FormHelperText, InputAdornment, InputBase} from "@material-ui/core";
import {Tools} from "../../shared/Tools";

export default class RouteList extends Component {

    constructor(props){
        super(props);

        this.state={
            searchText:"",
            filteredRoutes:[],
        }
    }

    _routesLoadedSubscription:any;

    componentDidMount(){
        if(this.props.routes.length > 0){
            this.setState({
                filteredRoutes:this.generateFilteredRoutesList(this.props.routes,this.state.searchText)
            })
        }
        else{
            this._routesLoadedSubscription = global.emitter.addListener("routesLoaded",()=>{
                this.setState({
                    filteredRoutes:this.generateFilteredRoutesList(this.props.routes,this.state.searchText)
                })
            })
        }
    }

    componentWillUnmount(): void {
        if(this._routesLoadedSubscription){this._routesLoadedSubscription.remove()}
    }

    onSearchTextChange(event){
        let search = event.target.value;
        this.setState({
            searchText:search,
            filteredRoutes:this.generateFilteredRoutesList(this.props.routes,search)
        })
    }

    clearSearch(){
        this.setState({
            searchText:"",
            filteredRoutes:this.props.routes,
        })
    }

    onStopHovered(stop){
        global.emitter.emit("stopHovered",stop)
    }

    generateFilteredRoutesList(allRoutes, searchText){
        let result = [];

        if(searchText.length === 0){
            result = allRoutes
        }
        else{
            for(let route of allRoutes){
                if(route.number.indexOf(searchText) !== -1){
                    result.push(route)
                }
            }
        }
        return result;
    }

    render() {

        return (
            <div style={{width:"100%"}}>

                <div style={{height:44, width:"100%", padding:4, display:"flex",
                flexDirection:"row", justifyContent:"flex-start", alignItems:'center', paddingLeft:8}}>

                    <InputBase
                        value={this.state.searchText}
                        style={{flex:1}}
                        placeholder={"Rechercher une ligne"}
                        onChange={(event) => {this.onSearchTextChange(event)}}
                        margin="none"
                        size={"small"}
                        inputProps={{ 'aria-label': 'naked' }}
                    />

                    <IconButton
                        style={{height:34, width:34}}
                        onClick={()=>{this.clearSearch()}}
                    >
                        <Icon style={{fontSize:22, color:"#797979", marginTop:-6}}>clear</Icon>
                    </IconButton>

                </div>

                <div style={{width:"100%"}} className={this.state.searchText.length > 0 ? "displayed-menu" : "hidden-menu"}>
                    <div style={{width:"100%", maxHeight:440, overflowY:"auto", borderTop:"1px solid #EFEFEF",}}
                         className={"styled-scroller"}>

                        {this.props.routeSelectedOnce !== true &&
                        <div style={{width:"100%", display:"flex", justifyCOntent:"center", alignItems:'center',backgroundColor:'#D9EDF7', minHeight:20}}>
                            <p style={{width:"100%",paddingLeft:8, paddingRight:8, color:'#2F549C', fontSize:14, textAlign:"center", margin:0,}}>
                                {"Cliquez sur une ligne ci-dessous pour afficher le détail"}
                            </p>
                        </div>

                        }


                        {this.state.filteredRoutes.map((route)=>{
                            return(
                                <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',
                                    paddingLeft:8, paddingRight:8}} key={route.id + ""} className={"hoverable-route"} onClick={()=>{this.props.onRouteSelected(route)}}>

                                    <RouteColorBox route={route}/>
                                    <div style={{flex:1}}/>

                                    {this.getRouteTraffic(route)}

                                </div>
                            )
                        })}

                    </div>

                </div>

            </div>
        )
    }

    getRouteTraffic(route){

        let status = null;
        if(route.traffic){
            status = route.traffic.status;
        }

        switch(status){

            case "DISRUPTED":
                return(
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:'center'}}>
                        <p style={{textAlign:"right", fontSize:14, fontWeight:"bold", color:'#E58527', margin:0, marginRight:4}}>
                            {"TRAFIC PERTURBÉ"}
                        </p>
                        <Icon style={{fontSize:22, color:"#E58527"}} className={"inverted"}>info</Icon>
                    </div>
                );
            case "VERY_DISRUPTED":
                return(
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:'center'}}>
                        <p style={{textAlign:"right", fontSize:14, fontWeight:"bold", color:'#E58527', margin:0, marginRight:4}}>
                            {"TRAFIC TRÈS PERTURBÉ"}
                        </p>
                        <Icon style={{fontSize:22, color:"#E58527"}} className={"inverted"}>info</Icon>
                    </div>
                );
            case "INTERRUPTED":
                return(
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:'center'}}>
                        <p style={{textAlign:"right", fontSize:14, fontWeight:"bold", color:'#E43525', margin:0, marginRight:4}}>
                            {"TRAFIC INTERROMPU"}
                        </p>
                        <Icon style={{fontSize:22, color:"#E43525"}}>cancel</Icon>
                    </div>
                );
            default:
                return(
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:'center'}}>
                        <p style={{textAlign:"right", fontSize:14, fontWeight:"normal", color:'#569F43', margin:0, marginRight:4}}>
                            {"Trafic normal"}
                        </p>
                        <Icon style={{fontSize:22, color:"#569F43"}}>check_circle</Icon>
                    </div>
                )

        }

    }

}
