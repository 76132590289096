import React, {Component} from 'react';
import * as d3 from "d3-delaunay"
import {Tools} from "../shared/Tools";
import {InfoWindow} from "react-google-maps";
import MiniRouteColorBox from "../shared/components/MiniRouteColorBox";

const MINI_BOX_WIDTH = 38;

export default class RoutesIndices extends Component {

    constructor(props){
        super(props);

        this.state={
            zoomLevel:null,
            boxWidthMeters:null,
            clusters:[],
        }
    }

    _routesLoadedSubscription:any;
    _mapZoomChangedSubscription:any;

    componentDidMount(){
        this._routesLoadedSubscription = global.emitter.addListener("routesLoaded",()=>{
            this.onRoutesLoaded(this.props.routes)
        });
        this._mapZoomChangedSubscription = global.emitter.addListener("mapZoomChanged",(zoom)=>{

            // let parisLat = 48.864716;
            // let boxWidthPx = 38;
            //
            // let metersPerPx = 156543.03392 * Math.cos(parisLat * Math.PI / 180) / Math.pow(2, zoom);
            // let boxWidthMeters = Math.round(boxWidthPx*metersPerPx);
            // console.log("Box width",boxWidthMeters);

            this.setState({
                zoomLevel:zoom
            })

        })
    }

    componentWillUnmount(): void {
        if(this._routesLoadedSubscription){this._routesLoadedSubscription.remove()}
        if(this._mapZoomChangedSubscription){this._mapZoomChangedSubscription.remove()}
    }

    onRoutesLoaded(routes,zoom=null){

        let allIndices = [];
        let id = 1;
        for(let route of routes){

            let first = route.variants[0].decodedPolyline[0];
            let last = route.variants[0].decodedPolyline[route.variants[0].decodedPolyline.length - 1];

            allIndices.push({id:id,lat:first.lat, lng:first.lng,route:route});
            id++;
            allIndices.push({id:id,lat:last.lat, lng:last.lng,route:route});
            id++;
        }

        let clusters = this.makeClusters(allIndices,150);

        let clid = 1;
        for(let cluster of clusters){
            let routes = [];
            for(let id of cluster.ids){
                routes.push(allIndices[id - 1].route)
            }
            cluster.routes = Tools.sortFlexnavRoutes(routes);
            cluster.id = clid;
            clid++;
        }

        this.setState({
            clusters:clusters
        });

    }


    makeClusters(initialArray, radius) {
        let getn = function(d3obj: d3.Delaunay, index) {
            let iterable = d3obj.neighbors(index);
            let res:Array<number> = [];
            for(let value of iterable) {
                res.push(value);
            }
            return res;
        };
        let cdb = function(p1,p2) {
            let lat1 = p1.lat;
            let lng1 = p1.lng;
            let lat2 = p2.lat;
            let lng2 = p2.lng;
            let pi80 = Math.PI / 180;
            lat1 *= pi80;
            lng1 *= pi80;
            lat2 *= pi80;
            lng2 *= pi80;
            let r = 6366.371;
            let dlat = lat2 - lat1;
            let dlng = lng2 - lng1;
            let a = Math.sin(dlat / 2) * Math.sin(dlat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlng / 2) * Math.sin(dlng / 2);
            let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            let km = r * c;
            return (km * 1000)
        };
        let workingA = initialArray.map((e) => { return {lat: e.lat, lng: e.lng, contained: [{lat: e.lat, lng: e.lng, id : e.id}]}});
        let processOver = false;
        let maxLoop = 0;
        while(!processOver || maxLoop > 10000) {
            maxLoop++;
            let tessel = d3.Delaunay.from(workingA.map(e => { return [e.lat, e.lng]}));
            for(let i = 0; i < workingA.length; i++) {
                let neiIndexes = getn(tessel, i);
                let breakWorkingA = false;
                for(let index of neiIndexes) {
                    let wai =  workingA[index];
                    if(cdb(wai, workingA[i]) < radius) {
                        workingA[i].contained.push(...wai.contained);
                        workingA = workingA.filter((_, i) => {return i !== index});
                        breakWorkingA = true;
                        break;
                    }
                }
                if(breakWorkingA) break;
                if(i === workingA.length - 1) {
                    processOver = true;
                }
            }
        }
        let result = [];
        for(let item of workingA) {
            let avgLat = item.contained.map(e => e.lat).reduce((a,b) => a+b, 0) / item.contained.length;
            let avgLng = item.contained.map(e => e.lng).reduce((a,b) => a+b, 0) / item.contained.length;
            result.push({
                lat: avgLat,
                lng: avgLng,
                ids: item.contained.map(e => e.id)
            });
        }
        return result;
    }

    getWrapperWidth(length){
        if(length === 1){
            return MINI_BOX_WIDTH;
        }
        else if(length >= 2 && length <6){
            return MINI_BOX_WIDTH*2;
        }
        else{
            return MINI_BOX_WIDTH*3;
        }

    }

    render() {

        if(this.props.routeSelected || !this.state.zoomLevel || (this.state.zoomLevel && this.state.zoomLevel < 12)){
            return null;
        }

        return (
            <template>

                {this.state.clusters.map((cluster)=>{
                    return(

                            <InfoWindow key={"cluster_" + cluster.id}
                                        zIndex={20 + cluster.routes.length}
                                        position={cluster}>
                                <div style={{width:this.getWrapperWidth(cluster.routes.length), display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
                                    {cluster.routes.map((route)=>{

                                        let opacity = 1;
                                        if(route.traffic){
                                            if(route.traffic.serviceLevel <= 0.75 && route.traffic.serviceLevel > 0){
                                                opacity = 0.85;
                                            }
                                            else if(route.traffic.serviceLevel === 0){
                                                opacity = 0.3;
                                            }
                                            else{
                                                opacity = 1;
                                            }
                                        }

                                        return(
                                            <div style={{opacity:opacity}} key={"cl" + cluster.id + "route" + route.id}>
                                                <MiniRouteColorBox
                                                    route={route}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </InfoWindow>

                    )
                })}

            </template>
        )
    }
}
