import React, {Component} from 'react';
import {Tools} from "../Tools";

export default class RouteColorBox extends Component {

    render(){

        if(!this.props.route){
            return null;
        }

        return(
            <div style={{height:26, display:"flex", justifyContent:"center", alignItems:'center', paddingLeft:6,
            paddingRight:6, backgroundColor:"#"+this.props.route.color,
                border:this.props.adaptive === true ? "1px solid " + Tools.getContrastYIQ(this.props.route.color) : "1px solid #222222", width:50}}>
                <p style={{color:Tools.getContrastYIQ(this.props.route.color), fontSize:26, fontWeight:"bold", margin:0}}
                className={"noselect"}>{this.props.route.number}</p>
            </div>
        )
    }

}
